import React from "react";
import { Link } from "react-router-dom";
export default   function SuccesUsuario() {     
  
    return (<>
        <div>
            <div className="usuario-success">
                <h4>Has creado una cuenta de cliente!</h4>
                <p>El usuario fue creado con éxito, en breve recibirá un correo de confirmación o iniciar sesión desde <Link to="/dashboard">aquí</Link>.</p>
            </div>
        </div>
    </>)
}